/*
upgrated filestack to 3.x.x version.
There were 2 changes in this file to be made to make it work

CHANGE-1) the hideWhenUploading: false  , is not supported now IN UPLOAD OPTIONS
CHANGE-2) The pickER function CloudUpload.pick(pickOptions); NO LONGER SUPPORTED NOW
*/
(function () {
    'use strict';

    angular
        .module('atheer')
        .directive('fpUpload', fpUpload);

    function fpUpload() {
        var directive = {
            restrict: 'E',
            scope: {
                'fpNgModel': '=?',
                'fpMultiple': '@',
                'fpAcceptedType': '@',
                'fpMaxSize': '@',
                'fpContentType': '@',
                'fpEnablePreview': '=?',
                'fpPattern': '@',
                'fpAddModelFn': '&?',
                'fpRemoveModelFn': '&?',
                'fpUploadType': "@?",
                'fpSmartUploadLabel': "@?",
                'fpSmartUploadIcon': "@?",
                'fpSmartUploadBackground': "@?",
                "fpBindFullObj": '=?',
                "previewWidth": '@?',
                "fpAllowMediaSelection": '=?',
                "fpUploadStatus": "=?",
                "fpUploadSizeError": "=?",
                "fpUploadIconClass": "@?",
                "fpAllButtonColorClass": "@?",
                "fpPreviewImageClass": "@?",
                "fpSupportedFileTypes": "@",
                "fpProcessDocument": "@"
            },
            controller: uploadController,
            templateUrl: 'core/components/upload/upload.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function uploadController($scope, $timeout, $mdToast, $mdDialog, Upload, CloudUpload, Media, AlertService) {
        $scope.status = $scope.fpUploadStatus ? $scope.fpUploadStatus : 'idle';

        $scope.fpUploadIconClass = $scope.fpUploadIconClass ? $scope.fpUploadIconClass : 'fa fa-upload';
        $scope.fpAllButtonColorClass = $scope.fpAllButtonColorClass ? $scope.fpAllButtonColorClass : 'md-primary';
        $scope.fpPreviewImageClass = $scope.fpPreviewImageClass ? $scope.fpPreviewImageClass : '';

        $scope.upload = upload;
        $scope.removeImage = removeImage;
        $scope.showImage = showImage;
        $scope.uploadReset = uploadReset;
        $scope.getPreviewUrl = getPreviewUrl;
        $scope.getFileName = getFileName;
        $scope.removeFile = removeFile;
        $scope.downloadConfigFile = downloadConfigFile;
        $scope.updateState = updateState;
        $scope.isDocumentForAI = false;
        $scope.isChecked = false;
        $scope.documentTags = [];

        //***************************************
        //Following variables and code is for Media Picker
        //*****************************************

        $scope.openMediaSelector = openMediaSelector;

        function updateState() {
            $scope.isChecked = !$scope.isChecked;
            $scope.isDocumentForAI = $scope.isChecked;
        }

        function openMediaSelector(ev) {
            $mdDialog.show({
                templateUrl: 'modules/system/media/media.html',
                targetEvent: ev,
                controller: 'MediaController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                skipHide: true,
                locals: {
                    pagingParams: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    isPickerView: true,
                    isMultiple: false
                }
            })
                .then(function (media) {
                    uploadComplete({
                        data: media
                    });
                });
        }

        //****************************************
        //following variables are for cloud upload
        //****************************************
        $scope.files = [];
        $scope.pickCloudFile = pickCloudFile;

        function upload(file) {
            if (file.length) {
                if (($scope.fpMaxSize === undefined && file[0].size < 1000000) || $scope.fpMaxSize) {
                    $scope.showUploadProgress = true;
                    $scope.status = 'uploading';
                    $scope.fpUploadStatus = 'uploading';
                    Upload.upload({
                        url: ($scope.fpContentType === 'file') ? 'api/homepage/upload' : 'api/media/upload',
                        data: {
                            'file': file,
                            'type': $scope.fpContentType
                        },
                        arrayKey: ''
                    }).then(function (resp) {
                        uploadComplete(resp);
                    }, function (resp) {
                        uploadError();
                    }, function (evt) {
                        uploadProgress(evt);
                    });
                } else {
                    uploadError();
                }
            }
        }

        function removeImage() {
            $scope.fpNgModel = null;
            execRemoveCallBack();
        }

        function removeFile() {
            $scope.fpNgModel = null;
            execRemoveCallBack();
        }

        function downloadConfigFile() {
            var downloadLink = document.createElement('a');
            downloadLink.href = 'media/documents/' + $scope.fpNgModel.split('|')[1] + '.json';
            downloadLink.download = getFileName();
            downloadLink.click();
        }

        function showImage() {
            return angular.isDefined($scope.fpNgModel) && $scope.fpNgModel !== "" && $scope.fpNgModel !== null;
        }

        function uploadReset() {
            $scope.status = 'idle';
            $scope.fpUploadStatus = 'idle';
        }

        function getPreviewUrl() {
            var previewUrl = '';
            if ($scope.showImage()) {
                previewUrl = $scope.fpNgModel.startsWith('http') ? $scope.fpNgModel : '/media/images/' + $scope.fpNgModel;
            }
            return previewUrl;
        }

        function getFileName() {
            var configFileName = '';
            if ($scope.showImage()) {
                configFileName = $scope.fpNgModel.split('|')[0];
            }
            return configFileName;
        }

        function pickCloudFile() {
            var maxFileSize = 2000000;

            if ($scope.fpMaxSize != null) {
                maxFileSize = $scope.fpMaxSize;
            };

            /*
              CHANGE-1: the hideWhenUploading: false  , is not supported now.
            */
            var pickOptions = {
                accept: getCloudUploadMimeTypes(),
                storeTo: {
                    location: "s3"
                },
                fromSources: cloudUploadServices(),
                startUploadingWhenMaxFilesReached: true,
                disableTransformer: true,
                maxSize: parseInt(maxFileSize),
                onFileUploadStarted: onCloudUploadStarted,
                onFileUploadFinished: onCloudUploadFinished,
                onFileUploadProgress: onCloudUploadProgress,
                onFileUploadFailed: onCloudUploadError,
                onClose: onCloudUploadClose
            };

            /*
              CHANGE-2) The pickER function CloudUpload.pick(pickOptions); 
              has been removed from the filestackPicker in 3.x.x version
              Instead we need to use CloudUpload.picker(pickOptions).open();
          */
            CloudUpload.picker(pickOptions).open();
        };

        //*********************************************************
        // Following are helper functions for cloud upload services
        //*********************************************************
        function getCloudUploadMimeTypes() {
            var mimeTypeArray = [];
            if($scope.isChecked) {
                mimeTypeArray.push("application/pdf")
            }
            else {
                angular.forEach($scope.fpPattern.split(','), function (mimeType) {
                    mimeTypeArray.push(mimeType);
                });
            }
            return mimeTypeArray;
        }

        function cloudUploadServices() {
            if ($scope.fpSmartUploadPattern == "image/*") {
                return ['local_file_system', 'googledrive', 'box', 'dropbox', 'clouddrive', 'onedrive', 'picasa', 'imagesearch'];
            } else {
                return ['local_file_system', 'googledrive', 'box', 'dropbox', 'clouddrive', 'onedrive'];
            }
        }

        function onCloudUploadClose() {
            $scope.status = 'idle';
            $scope.fpUploadStatus = 'idle';
        };

        function onCloudUploadStarted(file) {
            $scope.status = 'uploading';
            $scope.fpUploadStatus = 'uploading';
            $scope.showUploadProgress = false;
            $scope.$apply();
        };

        function onCloudUploadFinished(file) {
            $scope.status = 'complete';
            $scope.fpUploadStatus = 'complete';
            $scope.uploadPercentage = 0;

            var cloudUpload = (Array.isArray(file) ? file[0] : file);
            $scope.$apply();

            $timeout(function () {
                Media.cloudupload({
                    type: $scope.fpContentType
                }, cloudUpload, execCloudUploadCallBack, uploadError);
            }, 2000);
        };

        function onCloudUploadError(file, error) {
            $scope.status = 'error';
            $scope.fpUploadStatus = 'error';
            $scope.uploadPercentage = 0;
            $scope.$apply();
        };

        function onCloudUploadProgress(file, progressEvent) {
            $scope.showUploadProgress = true;
            $scope.uploadPercentage = progressEvent.totalPercent;
            $scope.$apply();
        };

        function execCloudUploadCallBack(response) {
            $scope.fpNgModel = $scope.fpBindFullObj ? response : response.cloud_url;
            if (angular.isDefined($scope.fpAddModelFn)) {
                var finalObj = {
                    mediaId: response.source_file_name,
                    responseObj: response
                };
                if ($scope.fpContentType === 'document') {
                    finalObj.isRequiredForProcessing = $scope.isDocumentForAI;
                    finalObj.documentTags = $scope.documentTags;
                }
                $scope.fpAddModelFn(finalObj);
            }
            uploadReset();
        }

        //*********************************************************
        // Following are helper functions for local upload services
        //*********************************************************
        function getLocalUploadMimeTypes() {
            return $scope.fpPattern.replace(",", "','");
        }

        function uploadComplete(resp) {
            $scope.status = 'complete';
            $scope.fpUploadStatus = 'complete';
            $scope.uploadPercentage = 0;
            $scope.fpNgModel = $scope.fpContentType === 'file' ? resp.data.source_file_name + '|' + resp.data.id : ($scope.fpBindFullObj ? resp.data : resp.data.properties.file_name);
            $scope.fpUploadSizeError = false;
            AlertService.clear();
            $timeout(function () {
                execAddCallBack(resp);
            }, 3000);
        }

        function uploadError(resp) {
            $scope.status = 'error';
            $scope.fpUploadStatus = 'error';
            $scope.fpUploadSizeError = true;
            $scope.uploadPercentage = 0;
            $timeout(function () {
                uploadReset();
            }, 3000);
        }

        function uploadProgress(evt) {
            $scope.fpUploadSizeError = false;
            $scope.uploadPercentage = parseInt(100.0 * evt.loaded / evt.total);
        }

        function execAddCallBack(resp) {
            if (angular.isDefined($scope.fpAddModelFn) && $scope.fpAddModelFn !== null) {
                $scope.fpAddModelFn({
                    mediaId: resp.data.properties.file_name,
                    responseObj: resp.data
                });
            }
            uploadReset();
        }

        function execRemoveCallBack() {
            if (angular.isDefined($scope.fpRemoveModelFn) && $scope.fpRemoveModelFn !== null) {
                $scope.fpRemoveModelFn();
            }
        }
    }
})();
